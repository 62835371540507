import React, { useState } from 'react';
import { Link } from 'gatsby';

const Menu = ({ lines, headerTitle, headerLink }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleShowSubMenu = () => setShowSubMenu(true);
  const handleHideSubMenu = () => setShowSubMenu(false);

  return <>
    <div
      onMouseEnter={handleShowSubMenu}
      onMouseLeave={handleHideSubMenu}
      style={{ position: 'relative' }}
    >
      <Link to={headerLink}>
        <span className="nav-small">{headerTitle}</span>
      </Link>

      {(showSubMenu && lines.length > 0) &&
        <>
          <ul className="menu-list">
            {lines.map((line, i) => <>
              <li
                style={{ padding: '8px', paddingLeft: 0 }}
                key={i}
              >
                <Link
                  to={line.link}
                  style={{ textDecoration: 'none' }}
                >
                  {line.title}
                </Link>
              </li>
            </>)}
          </ul>
        </>
      }
    </div>
  </>
}

export default Menu;