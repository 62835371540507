import React from 'react';
import { Link, StaticQuery } from 'gatsby';

const getSiteMap = (data) => {
  const pages = data.allMarkdownRemark.edges.map(edge => {
    return {
      link: edge.node.fields.slug,
      title: edge.node.frontmatter.title,
      order: edge.node.frontmatter.order,
      root: edge.node.frontmatter.root,
      rootOrder: edge.node.frontmatter.rootOrder,
      hideInMenu: edge.node.frontmatter.hideInMenu,
    }
  });

  const getRoot = (str) => str.split("/").filter(r => r !== "");

  const siteMap = pages
    .filter(page => page.root === true)
    .filter(page => {
      const arr = getRoot(page.link)
      return arr.length === 1;
    })
    .sort((a, b) => a.rootOrder - b.rootOrder)
    .map(parent => {
      const children = pages
        .filter(page => {
          const a = getRoot(page.link);
          const b = getRoot(parent.link);
          return a[0] === b[0];
        })
        .filter(r => !r.root && !r.hideInMenu)
        .sort((a, b) => a.order - b.order);

      return { parent, children }
    })
  return siteMap;
}

export const FooterSiteMapComp = (props) => {
  const { sitemap } = props;

  return <div className="alt-bg2 container" style={{ display: 'flex', flexDirection: 'row', fontSize: '12px', justifyContent: 'space-between' }}>
    {sitemap.map((page, idx) => <div key={idx}>
      <div>
        <Link style={{ fontSize: '16px', fontWeight: 'bolder' }} to={page.parent.link}>{page.parent.title}</Link>
        {page.children.map((child, i) => <div key={i}>
          <span><Link to={child.link}>{child.title}</Link></span>
        </div>)}
      </div>
    </div>)}
  </div>
}

const SiteMap = (props) => {
  const InputComponent = props.inputComponent;

  return <StaticQuery
    query={graphql`
    {
      allMarkdownRemark(limit: 1000) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              templateKey,
              title,
              order,
              root,
              rootOrder,
              hideInMenu
            }
          }
        }
      }
    }
  `}
    render={data => {
      const sitemap = getSiteMap(data);
      return <InputComponent sitemap={sitemap} {...props.inputProps} />
    }} />
};

export default SiteMap;
