import React, { useState } from 'react';
import { Link } from 'gatsby'
import SiteMap from './SiteMap';
import Menu from './Menu';

const Logo = () => <>
  <div>
    <Link to="/" id="logo" >
      <img alt="" src="/img/logo.png" style={{ width: '80px' }} /></Link>
  </div>
</>

const MyPages = () => <>
  <Link to="/flytjendur-og-utgefendur/minar_sidur">
    <button className="btn btn-outlined nav-button">Mínar síður</button>
  </Link>
</>

const NavigationDesktop = (props) => {
  const { sitemap } = props;

  return <>
    <nav style={{ display: 'flex', flexDirection: 'row' }}>
      {sitemap.map((page, idx) =>
        <div key={idx} className="nav-links">
          <Menu
            lines={page.children}
            headerTitle={page.parent.title}
            headerLink={page.parent.link}
          />
        </div>
      )}

      <MyPages />
    </nav>
  </>
}

const NavigationMobile = (props) => {
  const { sitemap } = props;

  return <>
    <div className="hamb">
      <div className="hamb-up">
        <div>
          <Logo />
        </div>
        <div>
          <div
            style={{ fontSize: '28px', cursor: 'pointer', paddingRight: '24px' }}
            onClick={() => props.onClose()}
            className="fas fa-window-close hamburger"
          ></div>
        </div>
      </div>
      <nav >
        {sitemap.map((page, idx) =>
          <div key={idx} className="nav-small">
            <Link to={page.parent.link}>{page.parent.title}</Link>
          </div>
        )}

        <MyPages />
      </nav>
    </div>
  </>
}

const Header = (props) => {
  const { isDesktop } = props;
  const [showMenu, setShowMenu] = useState(false);

  return <>
    <header className="navbar">
      <Logo />

      {isDesktop ?
        <>
          <SiteMap inputComponent={NavigationDesktop} />
        </> :
        <>
          <i className="fas fa-bars hamburger" onClick={() => setShowMenu(true)}></i>
          {showMenu && <>
            <SiteMap
              inputComponent={NavigationMobile}
              inputProps={{ onClose: () => setShowMenu(false) }}
            />
          </>}
        </>}
    </header>
  </>
}

export default Header;