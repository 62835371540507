import React from 'react'

import { Helmet } from 'react-helmet'
// import useSiteMetadata from './SiteMetadata'
import { useMediaQuery } from '../hooks/useMediaQuery'
import Footer from './Footer';
import Header from './Header';
import SiteMap, { FooterSiteMapComp } from './SiteMap';

const TemplateWrapper = (props) => {
  const { children } = props;
  // const { title, description } = useSiteMetadata();
  const isDesktop = useMediaQuery('(min-width: 900px)');

  return <>
    <div>
      <Helmet>
        <html lang="en" />
        {/* <title>{title}</title> */}
        <title>SFH</title>
        {/* <meta name="description" content={description} /> */}

      </Helmet>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css" integrity="sha512-HK5fgLBL+xu6dm/Ii3z4xhlSUyZgTT9tuc/hSrtw6uzJOvgRr2a9jyxxT1ely+B+xFAmJKVSTbpM/CuL7qxO8w==" crossOrigin="anonymous" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat" />
      <link rel="stylesheet" type="text/css" href="/styles.css" />

      <Header isDesktop={isDesktop} />

      <main>
        <div className="main-content">{children}</div>
      </main>

      <SiteMap inputComponent={FooterSiteMapComp} />
      <Footer />
    </div>
  </>
}

export default TemplateWrapper;
