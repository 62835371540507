import React from 'react'

const Footer = (props) => {
  return <>
    <footer className="footer">
      <div className="container">
        SFH - Samband flytjenda og hljómplötuframleiðenda<br />
        Austurströnd 3, 170 Seltjarnarnes<br />
        Sími: 899 5687 <br />
        sfh@sfh.is <br />
        Kennitala: 620976-0169
      </div>
    </footer>
  </>
}

export default Footer
